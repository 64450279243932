import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./components/home/home.component";
import { ComponentLibraryModule } from "@srd/app-common-components";
import { AngularKiteCoreModule } from "@kite/angular-kite";
import { UnauthorizedComponent } from "./components/unauthorized/unauthorized.component";
import { AppRoutingModule } from "../app-routing.module";
import { BatchAddUserDialogComponent } from "./components/batch-add-user-dialog/batch-add-user-dialog.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [
        HomeComponent,
        UnauthorizedComponent,
        BatchAddUserDialogComponent,
        ConfirmationModalComponent,
    ],
    imports: [
        CommonModule,
        ComponentLibraryModule,
        AngularKiteCoreModule,
        AppRoutingModule,
        MatIconModule
    ],
    providers: [
        {
          provide: MatDialogRef,
          useValue: {}
        },
     ],
})
export class SharedModule { }
