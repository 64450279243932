import { ESrdIcon } from "@srd/app-common-components";
import { SideNavLink } from "@srd/app-common-components/lib/models/side-nav-link";

export enum NavLinkTitle {
  Home = "Home",
  IdentityManagement = "Identity Management",
  ServiceManagement = "Service Management",
  DataManagement = "Data Management",
  AppConfiguration = "App Configuration"
}

export const sideNavLinks: SideNavLink[][] = [
  [
    {
      title: NavLinkTitle.Home,
      route: "/home",
      icon: ESrdIcon.KI_HOME_F,
    },
  ],
  [
    {
      title: NavLinkTitle.IdentityManagement,
      route: "/identity-mgmt",
      icon: ESrdIcon.KI_PERSON_F,
    },
    {
      title: NavLinkTitle.ServiceManagement,
      route: "/service-mgmt",
      icon: ESrdIcon.KI_ACCOUNT_F,
    },
    {
      title: NavLinkTitle.DataManagement,
      route: "/data-mgmt",
      icon: ESrdIcon.KI_SIGNAL_F,
    },
    {
      title: NavLinkTitle.AppConfiguration,
      route: "/app-config",
      icon: ESrdIcon.KI_LAPTOP_F,
    }
  ],
];

export const childSideNavLinks: { [key: string]: SideNavLink[] } = {
  iam: [
    { title: 'Users', route: '/user-mgmt', icon: ESrdIcon.KI_LAPTOP },
    { title: 'Capabilities', route: '/capability-mgmt', icon: ESrdIcon.KI_APPLE },
    { title: 'Permissions', route: '/permission-mgmt', icon: ESrdIcon.KI_BILLING },
  ],
};
